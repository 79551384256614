<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          :show="$apollo.loading"
          spinner-variant="primary"
        >
          <b-card>
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                {{ program.title }}
              </h3>
            </b-card-header>

            <b-tabs
              v-model="activeTab"
              active
              nav-class="mb-3"
              pills
              class="pb-2"
              @input="updateHash"
            >
              <b-tab
                active
                class="text-left"
                href="Modules"
                lazy
                title="Modules"
              >
                <ModuleManager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Mentors"
                lazy
                title="Mentors"
              >
                <MentorManager />
              </b-tab>
              <b-tab
                href="Sessions"
                lazy
                title="Sessions"
              >
                <VideoConferencing />
              </b-tab>
              <b-tab
                class="text-left"
                href="Workshops"
                lazy
                title="Workshops"
              >
                <WorkshopManager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Masterclass"
                lazy
                title="Masterclass"
              >
                <MasterClassManager />
              </b-tab>

              <b-tab
                class="text-left"
                href="Exercises"
                lazy
                title="Exercises"
              >
                <Exercises />
              </b-tab>
              <b-tab
                title-item-class="calendarbtn"
                class="text-left"
                href="Calendar"
                lazy
                title="Calendar"
              >
                <Activities />
              </b-tab>
              <b-tab
                class="text-left"
                href="Milestone"
                lazy
                title="Milestone"
              >
                <Milestone />
              </b-tab>
              <b-tab
                href="Resources"
                lazy
                title="Library"
              >
                <Resources />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BOverlay, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Milestone from '@/views/startup-portal/pages/programs/enter/Milestone.vue'
import gql from 'graphql-tag'
import Activities from './Activities.vue'
import Exercises from './Review.vue'
import Resources from './KnowledgeBase.vue'
import VideoConferencing from './Emeet.vue'
import WorkshopManager from './WorkshopManager.vue'
import MasterClassManager from './MasterClassManager.vue'
import ModuleManager from './ModuleManager.vue'
import MentorManager from './MentorManager.vue'

export default {
  components: {
    Activities,
    Exercises,
    Resources,
    VideoConferencing,
    Milestone,
    WorkshopManager,
    MasterClassManager,
    ModuleManager,
    MentorManager,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BOverlay,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['#Modules',
        '#Mentors',
        '#Sessions',
        '#Workshops',
        '#Masterclass',
        '#Exercises',
        '#Calendar',
        '#Milestone',
        '#Resources'],
      program: {},
    }
  },
  watch: {
    // Watch for changes in route, activate corresponding tab if hash is changed
    $route: {
      handler(to, from) {
        if (to.hash !== from.hash) {
          this.activeTab = this.tabs.findIndex(tab => tab === to.hash)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.id}"){
          title
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.calendarbtn{
  position: absolute;
  right: 20px;
  top: 20px;
  border: solid 1px #7367F0;
  border-radius: 8px;
}
</style>
